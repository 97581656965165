



























































import { popoStore } from '@/stores/popo-store'
import { Observer } from 'mobx-vue'
import { Component, Inject, Vue } from 'vue-property-decorator'
import { ProfileNftViewModel } from '../../viewmodels/profile-nft-viewmodel'

@Observer
@Component({
  components: {
    'nft-statstic': () => import('@/modules/profile/components/nft-tabs/nft-statstic.vue'),
    'decorated-popo-empty': () => import('@/modules/reward/components/decorated-popo-empty.vue'),
  },
})
export default class extends Vue {
  @Inject({}) vm!: ProfileNftViewModel

  popoStore = popoStore

  get itemPerRow() {
    return this.$vuetify.breakpoint.xlOnly ? 4 : this.$vuetify.breakpoint.lgAndUp ? 3 : 2
  }
}
